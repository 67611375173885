import axios from 'axios';
import type { GraphQLError } from 'graphql';
import { PAYMENT_PLATFORM_API_URL } from '@peloton/app-config';
import {
  toCountryFromLocale,
  toLocaleFromHostname,
  Locale,
  toCurrency,
} from '@peloton/internationalize';
import { getCartTotal } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import {
  ShopCartUpdateActionType,
  ShopPaymentType,
} from '@ecomm/graphql/types.generated';
import type { ShopCartUpdateAction } from '@ecomm/graphql/types.generated';
import type {
  CtCartFragment,
  ShopPaymentDataFragment,
} from '@ecomm/shop-cart/graphql/fragments.generated';

let locale = Locale.Default;
if (typeof window != 'undefined') {
  locale = toLocaleFromHostname();
}
const country: any = toCountryFromLocale(locale);
const currency = toCurrency(country);

export const successResponseMessage = 'Completed OK';
export const transactionSystem = 'WEB';
export const giftCardLabel = 'giftCard';
export const PAYMENT_FAILURE_ERROR_CODE = 'PAYMENTS_INVALID';
export const CHECKOUT_CART_FAILED_ERROR_CODE = 'CHECKOUT_CART_FAILED';

export const createPaymentMethod = async (
  cardNumber: string,
  cardPin: string,
  logError: (err: string) => void,
) => {
  try {
    const response = await axios.post(
      `${PAYMENT_PLATFORM_API_URL}/payments/payment-methods`,
      {
        gift_card: {
          transaction_system: transactionSystem,
          card_number: cardNumber,
          pin: cardPin,
          country: country.toUpperCase(),
          currency: currency,
        },
      },
    );
    return response;
  } catch (error) {
    logError(
      `Gift Card Error | Payment method is not created: ${error?.response?.data?.message}`,
    );
    return error?.response ?? error;
  }
};

export const isGiftCardOnlyPresent = (
  paymentArray: ShopPaymentDataFragment[] | undefined,
) => {
  const filteredPayments = (paymentArray || []).filter(
    (payment: ShopPaymentDataFragment) =>
      payment.type !== ShopPaymentType.PelotonGiftCard,
  );
  return !Boolean(filteredPayments.length);
};

export const getAppliedGiftCards = (paymentArray: ShopPaymentDataFragment[]) => {
  const appliedGiftCards =
    paymentArray &&
    paymentArray.filter(
      (payment: ShopPaymentDataFragment) =>
        payment.type === ShopPaymentType.PelotonGiftCard &&
        payment.paymentLabel === giftCardLabel,
    );
  return appliedGiftCards || [];
};

export const getGiftCardTotal = (paymentArray: ShopPaymentDataFragment[]) => {
  let giftCardTotal = 0;
  const appliedGiftCards = getAppliedGiftCards(paymentArray);
  appliedGiftCards.forEach((giftCard: ShopPaymentDataFragment) => {
    giftCardTotal += giftCard.totalAmount.centAmount;
  });
  return giftCardTotal;
};

export const getOutstandingCartTotal = (shopCartData?: CtCartFragment | null) => {
  const cartTotal = getCartTotal(shopCartData);
  const giftCardTotal = getGiftCardTotal(shopCartData?.payments || []);
  if (cartTotal >= giftCardTotal) {
    return cartTotal - giftCardTotal;
  }

  return cartTotal;
};

export const getLast4DigitsArray = (paymentArray: ShopPaymentDataFragment[]) => {
  const last4DigitsArray: string[] =
    paymentArray &&
    paymentArray
      .filter(
        (payment: ShopPaymentDataFragment) =>
          payment.type === ShopPaymentType.PelotonGiftCard &&
          payment.paymentLabel === giftCardLabel,
      )
      .map(
        (giftCard: ShopPaymentDataFragment) =>
          giftCard.giftCardMetadata?.giftCardNumber?.slice(-4) ?? '',
      );
  return last4DigitsArray;
};

export const isGiftcardWithBalanceExists = (
  giftCardsArray: ShopPaymentDataFragment[],
) => {
  return giftCardsArray.find((giftCard: ShopPaymentDataFragment) => {
    return parseInt(giftCard.giftCardMetadata?.balanceAmount || '0');
  });
};

export const isGiftCardAlreadyAdded = (
  payment_method_token: string,
  paymentArray: ShopPaymentDataFragment[],
) => {
  const giftCards = getAppliedGiftCards(paymentArray);
  return giftCards.some(
    (giftCard: ShopPaymentDataFragment) => giftCard.paymentToken === payment_method_token,
  );
};

export const isPaymentFailureErrorPresent = (
  errors: readonly GraphQLError[] | undefined,
) => {
  const paymentFailureError = (errors || []).find(
    error => error.extensions?.errorCode === PAYMENT_FAILURE_ERROR_CODE,
  );
  return Boolean(paymentFailureError);
};

export const isInvalidReferralCodeError = (
  errors: readonly GraphQLError[] | undefined,
) => {
  const referralCodeError = (errors || []).find(
    error =>
      error.extensions?.errorCode === CHECKOUT_CART_FAILED_ERROR_CODE &&
      error?.message?.includes('InvalidDiscount'),
  );
  return Boolean(referralCodeError);
};

export const createUpdatePaymentAction = (
  giftCard: ShopPaymentDataFragment,
  appliedAmount: number,
  newBalance: number,
) => {
  const action = [
    {
      removePayment: {
        id: giftCard.id,
        token: giftCard.paymentToken,
        type: ShopPaymentType.PelotonGiftCard,
      },
      actionType: ShopCartUpdateActionType.RemovePayment,
    },
    {
      addPayment: {
        paymentDraft: {
          giftCardMetadata: {
            authorizationId: giftCard.paymentToken,
            balanceAmount: newBalance.toString(),
            giftCardNumber: giftCard.giftCardMetadata?.giftCardNumber || '',
          },
          paymentLabel: giftCardLabel,
          paymentToken: giftCard.paymentToken,
          totalAmountInCents: appliedAmount,
          type: ShopPaymentType.PelotonGiftCard,
        },
      },
      actionType: ShopCartUpdateActionType.AddPayment,
    },
  ];
  return action;
};

export const createUnchangedPaymentAction = (giftCard: ShopPaymentDataFragment) => {
  const action = [
    {
      removePayment: {
        id: giftCard.id,
        token: giftCard.paymentToken,
        type: ShopPaymentType.PelotonGiftCard,
      },
      actionType: ShopCartUpdateActionType.RemovePayment,
    },
    {
      addPayment: {
        paymentDraft: {
          giftCardMetadata: {
            authorizationId: giftCard.paymentToken,
            balanceAmount: giftCard.giftCardMetadata?.balanceAmount || '0',
            giftCardNumber: giftCard.giftCardMetadata?.giftCardNumber || '',
          },
          paymentLabel: giftCardLabel,
          paymentToken: giftCard.paymentToken,
          totalAmountInCents: giftCard.totalAmount.centAmount,
          type: ShopPaymentType.PelotonGiftCard,
        },
      },
      actionType: ShopCartUpdateActionType.AddPayment,
    },
  ];
  return action;
};

export const createRemovePaymentAction = (id: string, token: string) => {
  const action = {
    removePayment: {
      id: id,
      token: token,
      type: ShopPaymentType.PelotonGiftCard,
    },
    actionType: ShopCartUpdateActionType.RemovePayment,
  };
  return action;
};

export const handleRecalculate = (
  cartTotal: number,
  outstandingCartTotal: number,
  appliedGiftCards: ShopPaymentDataFragment[],
  handleUpdatePayments: (actionsArray: ShopCartUpdateAction[]) => Promise<void>,
) => {
  const giftCardWithBalanceExists = isGiftcardWithBalanceExists(appliedGiftCards);
  let actionsArray: ShopCartUpdateAction[] = [];
  if (outstandingCartTotal > 0 && giftCardWithBalanceExists) {
    actionsArray = handleRecalculateWhenProductsAdded(
      outstandingCartTotal,
      appliedGiftCards,
    );
  }
  if (outstandingCartTotal < 0) {
    actionsArray = handleRecalculateWhenProductsRemoved(cartTotal, appliedGiftCards);
  }
  if (cartTotal === outstandingCartTotal) {
    actionsArray = handleRecalculateWhenProductsRemoved(cartTotal, appliedGiftCards);
  }
  if (actionsArray.length > 0) {
    handleUpdatePayments(actionsArray);
  }
};

export const handleRecalculateWhenProductsAdded = (
  outstandingCartTotal: number,
  appliedGiftCards: ShopPaymentDataFragment[],
) => {
  let amountToBeIncreased = outstandingCartTotal;
  const actionsArray: ShopCartUpdateAction[] = [];
  appliedGiftCards.forEach((giftCard: ShopPaymentDataFragment) => {
    const gcBalance = parseInt(giftCard.giftCardMetadata?.balanceAmount || '0');
    if (gcBalance > 0 && amountToBeIncreased > 0) {
      const appliedAmount =
        outstandingCartTotal >= gcBalance ? gcBalance : outstandingCartTotal;
      const newBalance =
        outstandingCartTotal >= gcBalance ? 0 : gcBalance - outstandingCartTotal;
      const actions = createUpdatePaymentAction(
        giftCard,
        giftCard.totalAmount.centAmount + appliedAmount,
        newBalance,
      );
      actionsArray.push(...actions);
      amountToBeIncreased = amountToBeIncreased - appliedAmount;
    } else {
      const actions = createUnchangedPaymentAction(giftCard);
      actionsArray.push(...actions);
    }
  });
  return actionsArray;
};

export const handleRecalculateWhenProductsRemoved = (
  cartTotal: number,
  appliedGiftCards: ShopPaymentDataFragment[],
) => {
  let amountToBeTaken = cartTotal;
  const actionsArray: ShopCartUpdateAction[] = [];
  appliedGiftCards.forEach((giftCard: ShopPaymentDataFragment) => {
    if (amountToBeTaken > 0 && amountToBeTaken >= giftCard.totalAmount.centAmount) {
      const actions = createUnchangedPaymentAction(giftCard);
      actionsArray.push(...actions);
      amountToBeTaken = amountToBeTaken - giftCard.totalAmount.centAmount;
    } else if (amountToBeTaken > 0) {
      const gcOldBalance = parseInt(giftCard.giftCardMetadata?.balanceAmount || '0');
      const appliedAmount = amountToBeTaken;
      const newBalance = gcOldBalance + giftCard.totalAmount.centAmount - amountToBeTaken;
      const actions = createUpdatePaymentAction(giftCard, appliedAmount, newBalance);
      actionsArray.push(...actions);
      amountToBeTaken = 0;
    } else {
      const action = createRemovePaymentAction(giftCard.id, giftCard.paymentToken);
      actionsArray.push(action);
    }
  });
  return actionsArray;
};
